export default {
  methods: {
    getSubdomain() {
      let subdomain = window.location.host.split('.')[0].toLowerCase();
      subdomain = /^\d+&/.test(subdomain) ? 'app' : subdomain;

      const allowedSubdomains = [
        'alpha',
        'atallorder',
        'captive',
        'contentino',
        'creativecentury',
        'eagle',
        'encore',
        'fifthseason',
        'france24',
        'gate23',
        'horizon',
        'img',
        'inflightflix',
        'lionsgate',
        'lms',
        'pictureworks',
        'ricochet',
        'silkroute',
        'skeye',
        'tsi',
        'uplay',
      ];

      return allowedSubdomains.includes(subdomain) ? subdomain : 'app';
    }
  },
};
